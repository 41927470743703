"use client";

import { useState, useEffect } from "react";

import useDebounce from "./useDebounce";

const useGradientPosition = () => {
  const [gradientPosition, setGradientPosition] = useState({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(false);
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

  const debouncedUpdatePosition = useDebounce((event: MouseEvent) => {
    const { clientX, clientY } = event;
    setGradientPosition({ x: clientX * 0.33, y: clientY * 0.25 });
  }, 10);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const reducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)");
    setPrefersReducedMotion(reducedMotion.matches);

    window.addEventListener("mousemove", debouncedUpdatePosition);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("mousemove", debouncedUpdatePosition);
      window.removeEventListener("resize", handleResize);
    };
  }, [debouncedUpdatePosition]);

  return { gradientPosition, isMobile, prefersReducedMotion };
};

export default useGradientPosition;
